/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Image, Menu, Text, Subtitle, MenuWrap, MenuButton, MenuWrapBox, MenuButtonClose, Button, Title, SeparateLine, SeparateLineWrap, ContactForm } from '@swp/components'


export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"O nás"} description={"Informace o Kamnářství na Rozkoši"}>
        <Column className="pt--10" name={"my-oddelovaci-cara"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
          </ColumnWrap>

        </Column>


        <Column className="--l8w" name={"e118nxkkxu8"}>
          
          <ColumnWrap className="column__flex --center el--3" style={{"paddingRight":1008}} columns={"3"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/3a795bb8fa7745839f8db8d2bdf063bd_s=860x_.png"} url={"https://www.facebook.com/profile.php?id=61553456535181"} use={"url"} href={"https://www.facebook.com/profile.php?id=61553456535181"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/3a795bb8fa7745839f8db8d2bdf063bd_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/3a795bb8fa7745839f8db8d2bdf063bd_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/3a795bb8fa7745839f8db8d2bdf063bd_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/3a795bb8fa7745839f8db8d2bdf063bd_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/3a795bb8fa7745839f8db8d2bdf063bd_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="--left" style={{"maxWidth":100}}>
              
              <Image alt={"Obrázek editujete kliknutím"} src={"https://cdn.swbpg.com/t/17440/92f1889256de4868bfa1bf8c258f07ce_s=860x_.png"} url={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} use={"url"} href={"https://www.instagram.com/kamnarstvi_na_rozkosi/"} sizes={"(min-width: 40em) 28vw, 100vw"} style={{"maxWidth":100}} srcSet={"https://cdn.swbpg.com/t/17440/92f1889256de4868bfa1bf8c258f07ce_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/92f1889256de4868bfa1bf8c258f07ce_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/92f1889256de4868bfa1bf8c258f07ce_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/92f1889256de4868bfa1bf8c258f07ce_s=1400x_.png 1400w, https://cdn.swbpg.com/t/17440/92f1889256de4868bfa1bf8c258f07ce_s=2000x_.png 2000w"} lightbox={false}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu" menu={true} name={"my-hlavicka"}>
          
          <Menu className="flex--bottom" style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12 mt--02" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"KAMNÁŘSTVÍ NA ROZKOŠI<br>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton >
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/nabizime"} style={{"maxWidth":125}} target={""} content={"nabízíme"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/cenik"} style={{"maxWidth":125}} target={""} content={"ceník"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/poradna"} style={{"maxWidth":125}} target={""} content={"poradna"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/galerie"} style={{"maxWidth":125}} target={""} content={"GALERIE"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/aktuality"} style={{"maxWidth":125}} target={""} content={"AKTUALITY"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/o-nas"} style={{"maxWidth":125}} target={""} content={"O nás"}>
                </Button>

                <Button className="btn-box btn-box--hvr2 btn-box--pbtn2 btn-box--style2 btn-box--filling2 fs--12 w--600 btn-box--invert swpf--uppercase lh--1" href={"/kontakt"} style={{"maxWidth":125}} target={""} content={"kontakt"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column name={"kg6f6ac69h"}>
        </Column>


        <Column name={"my_cech"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box ff--3 fs--14" content={"člen Cechu kamnářů České republiky"}>
              </Title>

              <Image className="mt--08" src={"https://cdn.swbpg.com/o/17440/5c02d8768d2342198c276b8957ed27a4.jpg"} sizes="(max-width: 1439px) 100vw, 1440px" style={{"maxWidth":60}} srcSet={""}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10" name={"my-oddelovac-1"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="css-1k3ehu2 bg--center --parallax pb--30 pt--30" name={"my-vstup"} layout={"l1"} parallax={true} css={css`
      
    background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17440/bba4103b74704c42a4c761dc3982f653_r=90__s=350x_.JPG);
    @media (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17440/bba4103b74704c42a4c761dc3982f653_r=90__s=660x_.JPG);
    }
    @media (min-width: 661px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17440/bba4103b74704c42a4c761dc3982f653_r=90__s=860x_.JPG);
    }
    @media (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17440/bba4103b74704c42a4c761dc3982f653_r=90__s=1400x_.JPG);
    }
    @media (min-width: 1401px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17440/bba4103b74704c42a4c761dc3982f653_r=90__s=2000x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17440/bba4103b74704c42a4c761dc3982f653_r=90__s=660x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17440/bba4103b74704c42a4c761dc3982f653_r=90__s=1400x_.JPG);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: linear-gradient(rgba(0,0,0,0.15), rgba(0,0,0,0.15)), url(https://cdn.swbpg.com/t/17440/bba4103b74704c42a4c761dc3982f653_r=90__s=3000x_.JPG);
    }
  
    `}>
          
          <ColumnWrap className="column__flex --center el--1" style={{"maxWidth":700,"backgroundColor":"rgba(255,255,255,1)"}} border={null} columns={"1"}>
            
            <ColumnWrapper className="pb--02">
              
              <Title className="title-box ff--3 fs--36 mt--10" content={"PŘEDSTAVUJEME SE"}>
              </Title>

              <Text className="text-box fs--14 mt--16" content={"<span style=\"color: rgb(0, 0, 0);\">Kdesi mezi kopci Krkonoš a Orlických hor, na pomezí Kladska, pod památným místem Dobenín, u břehů jezera jménem Rozkoš, stojí malý slaměný domek. <br>A v něm - hle, kamnářství!<br><br>Právě zde se spolu snoubí tradice, řemeslný um a fortelná práce mistrů kamnářů. Tady se začíná cesta za poctivými kamny či sporákem. Topidlem, které se stane součástí vaší rodiny, oporou v třeskutých mrazech i hřejivým potěšením pro celé generace.<br><br>Vzpomínka na babiččiny buchty z malého kachlového sporáku, na jehož zápecí se pod péřovou duchnou tak krásně lehávalo, se může znovu stát realitou.<br><br>Vítejte v Kamnářství na Rozkoši.</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 mt--10 pb--10" href={"#my-popis"} target={null} content={"VÍCE"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pt--10" name={"my-oddelovac-2"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--10 pt--10" name={"my-popis"}>
          
          <ColumnWrap className="column__flex --border3 --center el--2" border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--10">
              
              <Title className="title-box ff--3 fs--30" content={"Kdo jsme?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10 mt--10">
              
              <Text className="text-box text-box--justify" content={"Jsme malé rodinné kamnářství. V současné době celý pracovní tým tvoří jeden muž a jedna žena. Přijde nám to pěkně vyvážené a i práci máme spravedlivě rozdělenou. Kamna vám navrhne a jako správný muž postaví kamnář Dan a všechno to papírování pak s úsměvem vyřeší Veronika. Je to úplně jako ze života, viďte?&nbsp;&nbsp;"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--14 mt--10" innerClassName="mt--16" href={"#my-fotky-nadpis"} target={null} content={"CHCI JE POZNAT!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --border3 --center el--2" border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--10">
              
              <Title className="title-box ff--3 fs--30" content={"Člen Cechu kamnářů České republiky!"}>
              </Title>

              <Image className="--left" src={"https://cdn.swbpg.com/o/17440/5c02d8768d2342198c276b8957ed27a4.jpg"} sizes="(max-width: 639px) 100vw, (max-width: 1439px) 50vw, 720px" style={{"maxWidth":100}} srcSet={""}>
              </Image>

            </ColumnWrapper>

            <ColumnWrapper className="mb--10 mt--10">
              
              <Text className="text-box text-box--justify" content={"Od roku 2022 jsme hrdými členy Cechu kamnářů České republiky.&nbsp;<br>Pro nás je to čest, pro vás záruka jistoty kvalitní práce a férového jednání, zaštítěná společností těch opravdových mistrů svého řemesla!<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--14 mt--10" innerClassName="mt--16" url={"https://www.cechkamnaru.cz/"} use={"url"} href={"https://www.cechkamnaru.cz/"} target={"_blank"} content={"CHCI ZJISTIT VÍCE O CECHU!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --border3 --center el--2" border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--10">
              
              <Title className="title-box fs--30" content={"Co umíme?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify mb--10 mt--10" content={"Je toho opravdu hodně...\n<br><br>Poradíme, co se nejlépe hodí právě pro vás a vysvětlíme i proč. Kreslíme 2D i 3D návrhy topidel, abyste viděli, jak to vybranému topidlu bude u vás doma slušet.\n<br><br>Nejen, že vám kamna nebo sporák rádi postavíme, ale také se vám o ně s láskou postaráme. A dokonce opečováváme i topidla, která jste třeba zdědili nebo vám je postavil jiný mistr kamnář. <br>Rádi se podělíme o své zkušenosti, takže jestli se potřebujete svěřit se svým kamnářským trápením, zavolejte nám. Určitě najdeme cestu ven.&nbsp;<br><br>Kamna a sporáky jsou naše srdcovka, ale rádi navrhneme i pece na pizzu nebo chleba, zkušenosti máme třeba i s vytápěným skleníkem. Nenechte se omezovat, my se totiž nebojíme žádného vašeho nápadu."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --border3 --center el--2" border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--10">
              
              <Title className="title-box fs--30" content={"Kde působíme?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Text className="text-box text-box--justify mb--10 mt--10" content={"Naším hlavním rajonem jsou Východní Čechy. Nebo chcete-li, východočeský kraj.<br>Pod palcem máme Policko, Českoskalicko, Novoměstsko, Náchodsko, Broumovsko i Trutnovsko.&nbsp;<br>Je tady vážně krásně - Jestřebí hory, Orlické hory, Krkonoše (a vůbec celé Podorlicko a Podkrkonoší), Kladsko, Kladské pomezí... Sem za vámi přijedeme extra rádi, protože jsme zdatní turisté a tak spojujeme příjemné s užitečným. Vám postavíme kamna a sobě protáhneme nohy v kopcích.<br>Ale nebojte, přijedeme kamkoliv budete potřebovat. Máme rádi celou Českou republiku a nikoho nevynecháme.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --border3 --center el--2" border={"3"} columns={"2"}>
            
            <ColumnWrapper className="--left mt--10">
              
              <Title className="title-box fs--30" content={"S kým spolupracujeme?"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="mt--10">
              
              <Text className="text-box text-box--justify" content={"Je nám ctí jmenovat zde naše dodavatele, kteří dodávají jednotlivé komponenty vašeho topidla. Ať už se jedná o šamotové cihly, krbové vložky, kachle a další materiály, bez kterých se žádný sporák nebo kamna neobejdou.&nbsp;<br>Můžete se na vlastní oči přesvědčit, že spolupracujeme se špičkami v oboru a kvalita tak pro nás není jen prázdný pojem.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10" name={"my-spoluprace"} layout={"l3"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17440/ee4b8513416c4a7faba9d10505a57758_s=660x_.png"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17440/ee4b8513416c4a7faba9d10505a57758_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/ee4b8513416c4a7faba9d10505a57758_s=660x_.png 660w"}>
              </Image>

              <Title className="title-box fs--22 mt--10" content={"Zámecká kamnářská manufaktura"}>
              </Title>

              <Text className="text-box ff--3 mt--16" content={"Když kachle, tak ze Zámecké kamnářské manufaktury.&nbsp;<br>Poctivá ruční práce podle tradičních postupů.&nbsp;<br>Kachle klasické i na přání zákazníka.&nbsp;<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--14 mt--16" href={"https://www.zamecka-kamnarska-manufaktura.cz/"} content={"DO MANUFAKTURY!"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17440/95c80e4aa5ab4653b2dc9cccdfc72cdf_s=660x_.png"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17440/95c80e4aa5ab4653b2dc9cccdfc72cdf_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/95c80e4aa5ab4653b2dc9cccdfc72cdf_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/95c80e4aa5ab4653b2dc9cccdfc72cdf_s=860x_.png 860w"} position={{"x":"0%","y":"0%"}}>
              </Image>

              <Title className="title-box fs--22 mt--10" content={"HESTIA CZ s.r.o."}>
              </Title>

              <Text className="text-box ff--3 mt--16" content={"Náš spolehlivý dodavatel kamnářského materiálu používaného pro stavbu našich topidel.<br>A také kamenická dílna.<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--14 mt--16" href={"https://hestia.cz/"} content={"DO HESTIA!"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image src={"https://cdn.swbpg.com/t/17440/62a7dba356224054bcd6479570a925ba_s=860x_.png"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":360}} srcSet={"https://cdn.swbpg.com/t/17440/62a7dba356224054bcd6479570a925ba_s=350x_.png 350w, https://cdn.swbpg.com/t/17440/62a7dba356224054bcd6479570a925ba_s=660x_.png 660w, https://cdn.swbpg.com/t/17440/62a7dba356224054bcd6479570a925ba_s=860x_.png 860w, https://cdn.swbpg.com/t/17440/62a7dba356224054bcd6479570a925ba_s=1400x_.png 1400w"} position={{"x":"-53.55555555555556%","y":"-100%"}}>
              </Image>

              <Title className="title-box fs--22 mt--10" content={"Banador s.r.o."}>
              </Title>

              <Text className="text-box ff--3 mt--16" content={"Skutečný velkoobchod materiály a potřebami pro kamnáře. Největší v ČR, s nejširší nabídkou. <br>Dodavatel krbových a kamnových vložek Hoxter.<br>"}>
              </Text>

              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--14 mt--16" href={"https://www.banador.cz/"} content={"DO BANADORU!"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"my-oddelovac-3"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--center" name={"my-fotky-nadpis"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--left">
              
              <Title className="title-box title-box--center" content={"A to jsme my...&nbsp;"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--10 pt--10" name={"my-fotky-polozky"} layout={"l4"}>
          
          <ColumnWrap className="column__flex --center el--3" columns={"3"}>
            
            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17440/9a54b11bb51e447280f016a6c8cd2143_e=0x132x1905x1429_s=860x_r=90_.jpeg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17440/9a54b11bb51e447280f016a6c8cd2143_e=0x132x1905x1429_s=350x_r=90_.jpeg 350w, https://cdn.swbpg.com/t/17440/9a54b11bb51e447280f016a6c8cd2143_e=0x132x1905x1429_s=660x_r=90_.jpeg 660w, https://cdn.swbpg.com/t/17440/9a54b11bb51e447280f016a6c8cd2143_e=0x132x1905x1429_s=860x_r=90_.jpeg 860w, https://cdn.swbpg.com/t/17440/9a54b11bb51e447280f016a6c8cd2143_e=0x132x1905x1429_s=1400x_r=90_.jpeg 1400w"} position={{"x":"-16%","y":"-13.846153846153857%"}}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: 700; text-decoration-line: underline;\">DAN</span><br>Kamnář tělem i duší, s hlavou plnou nápadů a dvěma šikovnýma rukama. Svěřte se mu se svou představou a navrhne vám to správné technické řešení. Na stavbě je k nezastavení a tak mu jde práce pěkně od ruky.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17440/1d969e31821c44348339281e7d070bab_s=860x_.JPG"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17440/1d969e31821c44348339281e7d070bab_s=350x_.JPG 350w, https://cdn.swbpg.com/t/17440/1d969e31821c44348339281e7d070bab_s=660x_.JPG 660w, https://cdn.swbpg.com/t/17440/1d969e31821c44348339281e7d070bab_s=860x_.JPG 860w, https://cdn.swbpg.com/t/17440/1d969e31821c44348339281e7d070bab_s=1400x_.JPG 1400w"} position={{"x":"-13.692307692307693%","y":"-55.23076923076923%"}}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: bold; text-decoration-line: underline;\">KOMÍN</span><br>Co by to bylo za kamnářství bez kočky, která by testovala, jestli je na kamnech to správné teplo. <br>V tomto je Komín opravdu nekompromisní.<br>A jo, je to kočka.<br>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper >
              
              <Image className="--shape5" src={"https://cdn.swbpg.com/t/17440/a7f4ff0fd4164d098524e017f64e9363_s=860x_.jpeg"} svg={false} ratio={"1:1"} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" style={{"maxWidth":260}} srcSet={"https://cdn.swbpg.com/t/17440/a7f4ff0fd4164d098524e017f64e9363_s=350x_.jpeg 350w, https://cdn.swbpg.com/t/17440/a7f4ff0fd4164d098524e017f64e9363_s=660x_.jpeg 660w, https://cdn.swbpg.com/t/17440/a7f4ff0fd4164d098524e017f64e9363_s=860x_.jpeg 860w, https://cdn.swbpg.com/t/17440/a7f4ff0fd4164d098524e017f64e9363_s=1400x_.jpeg 1400w"}>
              </Image>

              <Text className="text-box" content={"<span style=\"font-weight: 700; text-decoration-line: underline;\">VERONIKA</span><br>Pod palcem má nejen papírovací část našeho kamnářství, ale také tento web včetně fotek. Tak doufáme, že se vám líbí. Ten web.<br>Samozřejmě.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pt--10" name={"my-oddelovac-4"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--30 pt--30" name={"my-kontaktni-formular"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box swpf--uppercase" content={"kontaktní formulář"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--shape2" border={null}>
              
              <ContactForm className="--shape2 --style3" style={{"maxWidth":740}} action={"/contact"} fields={[{"name":"Celé jméno","type":"text","required":true,"placeholder":"Zadejte celé jméno"},{"name":"Kontaktní e-mail","type":"email","required":true,"placeholder":"Zadejte Váš e-mail"},{"name":"Telefonní číslo","type":"text","placeholder":"Telefon +420"},{"name":"Zpráva","type":"textarea","required":true,"placeholder":"Zadejte text zprávy"},{"name":"Odeslat","type":"submit"}]} layout={"l2"}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"my-oddelovac-5"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="--menu pb--08 pt--20" menu={true} name={"my-zapati"}>
          
          <Menu style={{"justify-content":"space-between"}}>
            
            <ColumnWrapper className="menu-logo-box">
              
              <Text className="text-box ff--2 w--600 swpf--uppercase ls--12" style={{"color":"rgb(0,0,0)"}} content={"<span style=\"font-weight: normal;\">DANIEL VLÁHA +420 776 780 996</span>"}>
              </Text>

              <Subtitle className="subtitle-box ff--3 w--400 swpf--uppercase ls--04 mt--0" content={"kamnářství na rozkoši"}>
              </Subtitle>

              <Subtitle className="subtitle-box ff--3 fs--12 w--800 mt--06" content={"<span style=\"font-weight: normal;\">člen Cechu kamnářů České republiky</span>"}>
              </Subtitle>

            </ColumnWrapper>

            <MenuWrap >
              
              <MenuButton className="--hvr2 --filling2">
              </MenuButton>

              <MenuWrapBox >
                
                <MenuButtonClose >
                </MenuButtonClose>

                <Button className="btn-box btn-box--sbtn1 btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/nabizime"} target={null} content={"NABÍZÍME"}>
                </Button>

                <Button className="btn-box btn-box--style2 fs--16 w--600 btn-box--invert lh--1" href={"/kontakt"} target={null} content={"KONTAKT"}>
                </Button>

              </MenuWrapBox>

            </MenuWrap>

          </Menu>

        </Column>


        <Column className="--center" name={"my-mesta"}>
          
          <ColumnWrap className="column__flex --center el--1 --full" columns={"1"} fullscreen={true}>
            
            <ColumnWrapper className="--left">
              
              <Text className="text-box text-box--center ff--3 fs--14 swpf--uppercase" content={"Náchod - česká skalice - nové město nad metují - červený kostelec - broumov - trutnov - dobruška - opočno - rychno nad kněžnou - police nad metují&nbsp;"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--menu" menu={true} name={"8c3mro6ctft"}>
        </Column>


        <Column name={"frre2ckhey"} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--left" style={{"maxWidth":480}}>
            </ColumnWrapper>

            <ColumnWrapper className="--center">
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--10" name={"my-oddelovac-6"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="pt--0" style={{"paddingBottom":2,"backgroundColor":"var(--color-variable-1)"}}>
            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"my-paticka"}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Button className="btn-box btn-box--pbtn2 btn-box--filling2 fs--11" href={"/zasady-ochrany-osobnich-udaju"} target={null} content={"ZÁSADY OCHRANY OSOBNÍCH ÚDAJŮ"}>
              </Button>

              <Text className="text-box ff--3 fs--14 mt--06" style={{"color":"rgb(0, 0, 0)"}} content={"Přečtěte si podrobné informace o našich Zásadách ochrany osobních údajů před dalším užíváním těchto stránek.<br>Veškeré zde zveřejněné informace a fotografie jsou majetkem Kamnářství na Rozkoši. Jejich šíření je bez souhlasu majitele zakázáno! Děkujeme, že respektujete autorská práva.<br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}